import React, { useState } from "react";
import "./Rooms.css";
import { useTranslation } from "react-i18next";
import masa1 from "../../../assets/images/masa1.png";
import masa2 from "../../../assets/images/masa2.png";
import rooms03 from "../../../assets/images/masa2.png";
import pokou1 from "../../../assets/images/pokou1.png"
import pokou2 from "../../../assets/images/POKOU02.png";
import mafa1 from "../../../assets/images/mafa1.png"
import mafa2 from "../../../assets/images/MAFA02.png";
import akwaba1 from "../../../assets/images/awkaba.png";
import akwaba2 from "../../../assets/images/akwaba2.png";
import { FaVideo } from "react-icons/fa";
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { IoIosCloseCircle } from "react-icons/io";
import Whats from '../../../assets/images/WhatsApp.mp4'


import Slider from "react-slick";


const Room = () => {
  const [t, i18n] = useTranslation("global");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const [showMore, setShowMore] = useState({ card1: false, card2: false, card3: false, card4: false, });
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleToggleShowMore = (card) => {
    setShowMore({ ...showMore, [card]: !showMore[card] });
  };

  const CustomModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={onClose}>
            <IoIosCloseCircle />
          </button>
          <div className="modal-content">
            <video width="640" height="360" controls type="video/mp4">
              <source src={Whats} />
            </video>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <section className="room top" id="room">
        <div className="container">
          <div className="head-cont">
            <h3>{t("rooms.heading-3")}</h3>
            <h1 style={{ fontSize: "25px" }}>{t("book.description")}</h1>
            <p>{t("rooms.par-one")}</p>
            <p>{t("rooms.par-2")}</p>
          </div>
          <div className="content grid">
            {/* HOTEL ROOMS CARDS 1 */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Slider {...settings}>
                <div className="box">
                  <div className="img">
                    <img src={masa1} alt="" />
                  </div>
                </div>
                <div className="box">
                  <div className="img">
                    <img src={masa2} alt="" />
                  </div>
                </div>
              </Slider>
              <div className="box">
                <div className={showMore.card1 ? "texst" : "texts"}>
                  <h3>{t("rooms.card1-h3")}</h3>
                  <p>
                    {showMore.card1
                      ? t("rooms.cards1-p") // Display full description
                      : t("rooms.card1-p")}
                    <button onClick={() => handleToggleShowMore('card1')} style={{ display: "inline-block", color: "#E4C590", paddingLeft: "5px" }}>
                      {showMore.card1 ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="rooms-fa-icons">
                  <i className="fas fa-bed"></i>
                  <i className="fas fa-wifi"></i>
                  <i className="fas fa-car"></i>
                  <i className="fas fa-coffee"></i>
                  <i className="fas fa-swimming-pool"></i>
                </div>
                <div class="book-now-container">
                  <div class="animation-container">
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                    <button
                      class="book-now-link"
                      onClick={() => {
                        window.open(
                          "https://live.ipms247.com/booking/book-rooms-hotellemarly"
                        );
                      }}
                    >
                      {t("hero.booknow")}{" "}
                      <span class="icon">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                    </button>
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARDS ONE END  */}
            {/* CARD 2 START */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Slider {...settings}>
                <div className="box">
                  <div className="img">
                    <img src={pokou1} alt="" />
                  </div>
                </div>
                <div className="box">
                  <div className="img">
                    <img src={pokou2} alt="" />
                  </div>
                </div>
              </Slider>
              <div className="box">
                <div className={showMore.card2 ? "texst" : "texts"}>
                  <h3>{t("rooms.card2-h3")}</h3>
                  <p>
                    {showMore.card2
                      ? t("rooms.cards2-p")
                      : t("rooms.card2-p")}
                    <button onClick={() => handleToggleShowMore('card2')} style={{ display: "inline-block", color: "#E4C590", paddingLeft: "5px" }}>
                      {showMore.card2 ? "Read Less" : "Read More"}
                    </button>
                  </p>
                  {/* <FaVideo style={{ color: "#E4C590", cursor: "pointer", fontSize: "22px" }}
                    onClick={handleShowModal}
                  /> */}
                </div>
                <div className="rooms-fa-icons">
                  <i className="fas fa-bed"></i>
                  <i className="fas fa-wifi"></i>
                  <i className="fas fa-car"></i>
                  <i className="fas fa-coffee"></i>
                  <i className="fas fa-swimming-pool"></i>
                </div>
                <div class="book-now-container">
                  <div class="animation-container">
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                    <button
                      class="book-now-link"
                      onClick={() => {
                        window.open(
                          "https://live.ipms247.com/booking/book-rooms-hotellemarly"
                        );
                      }}
                    >
                      {t("hero.booknow")}{" "}
                      <span class="icon">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                    </button>
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 2 END */}

            {/* CARD 3 START */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Slider {...settings}>
                <div className="box">
                  <div className="img">
                    <img src={mafa1} alt="" />
                  </div>
                </div>
                <div className="box">
                  <div className="img">
                    <img src={mafa2} alt="" />
                  </div>
                </div>
              </Slider>
              <div className="box">
                <div className={showMore.card3 ? "texst" : "texts"}>
                  <h3>{t("rooms.card3-h3")}</h3>
                  <p>
                    {showMore.card3
                      ? t("rooms.cards3-p")
                      : t("rooms.card3-p")}
                    <button onClick={() => handleToggleShowMore('card3')} style={{ display: "inline-block", color: "#E4C590", paddingLeft: "5px" }}>
                      {showMore.card3 ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="rooms-fa-icons">
                  <i className="fas fa-bed"></i>
                  <i className="fas fa-wifi"></i>
                  <i className="fas fa-car"></i>
                  <i className="fas fa-coffee"></i>
                  <i className="fas fa-swimming-pool"></i>
                </div>
                <div class="book-now-container">
                  <div class="animation-container">
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                    <button
                      class="book-now-link"
                      onClick={() => {
                        window.open(
                          "https://live.ipms247.com/booking/book-rooms-hotellemarly"
                        );
                      }}
                    >
                      {t("hero.booknow")}{" "}
                      <span class="icon">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                    </button>
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 4 START */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Slider {...settings}>
                <div className="box">
                  <div className="img">
                    <img src={akwaba1} alt="" />
                  </div>
                </div>
                <div className="box">
                  <div className="img">
                    <img src={akwaba2} alt="" />
                  </div>
                </div>
              </Slider>
              <div className="box">
                <div className={showMore.card4 ? "texst" : "texts"}>
                  <h3>{t("rooms.card0-h3")}</h3>
                  <p>
                    {showMore.card4
                      ? t("rooms.cards25-p")
                      : t("rooms.card25-p")}
                    <button onClick={() => handleToggleShowMore('card4')} style={{ display: "inline-block", color: "#E4C590", paddingLeft: "5px" }}>
                      {showMore.card4 ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="rooms-fa-icons">
                  <i className="fas fa-bed"></i>
                  <i className="fas fa-wifi"></i>
                  <i className="fas fa-car"></i>
                  <i className="fas fa-coffee"></i>
                  <i className="fas fa-swimming-pool"></i>
                </div>
                <div class="book-now-container">
                  <div class="animation-container">
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                    <button
                      class="book-now-link"
                      onClick={() => {
                        window.open(
                          "https://live.ipms247.com/booking/book-rooms-hotellemarly"
                        );
                      }}
                    >
                      {t("hero.booknow")}{" "}
                      <span class="icon">
                        <i class="fas fa-calendar-alt"></i>
                      </span>
                    </button>
                    <div class="round-objects">
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                      <div class="round-object"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CARD 4 END */}
        </div >
      </section>
      {/* <CustomModal isOpen={showModal} onClose={handleCloseModal} /> */}
    </div >

  );
};

export default Room;
