import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import global_en from './translations/en/global.json';
import global_fr from './translations/fr/global.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

const preferredLanguage = localStorage.getItem('preferredLanguage') || 'fr';

i18next.init({
  interpolation: { escapeValue: false },
  lng: preferredLanguage,
  resources: {
    en: { global: global_en },
    fr: { global: global_fr },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
