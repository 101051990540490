import React from "react";
import EngEaster from '../../../assets/images/MARLY_Easter_Pascal.png'
import FrnWeekend from '../../../assets/images/Weekend_Pascal_MARLY.png'
import EngBuft from '../../../assets/images/MARLY_Carnivorous_Buffet.png'
import FrnBuft from '../../../assets/images/Buffet_Carnivore_MARLY.png'

import { useTranslation } from "react-i18next";

const UpcomingEvents = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;

  // Define image sources based on language
  const imageSource = currentLanguage === "en" ? EngEaster : FrnWeekend;
  const imageSources = currentLanguage === "en" ? EngBuft : FrnBuft;

  return (
    <div className="container">
      <p className="section-subtitle label-2 text-center">{t("event.ev-h")}</p>

      <h2 className="section-title headline-1 text-center">
        {t("event.ev-h1")}
      </h2>

      <ul style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
        {/* <li>
          <div className="event-card has-before hover:shine">
            <div
              className="card-banner img-holder"
              style={{ "--width": 350, "--height": 450 }}
            >
              <img
                src={imageSource}
                width="350"
                height="450"
                loading="lazy"
                alt="Flavour so good you’ll try to eat with your eyes."
                // className="img-cover"
              />

              <time className="publish-date label-2" datetime="2022-09-15">
              {t("event.evnt")}
              </time>
            </div>

            <div className="card-content">
              <p className="card-subtitle label-2 text-center">
                {t("event.evt")}
              </p>

              <h3 className="card-title title-2 text-center">
                {t("event.pce")}
              </h3>
            </div>
          </div>
        </li> */}

        <li>
          <div className="event-card has-before hover:shine">
            <div
              className="card-banner img-holder"
              style={{ "--width": 350, "--height": 450 }}
            >
              <img
                src={imageSources}
                width="350"
                height="450"
                loading="lazy"
                alt="Flavour so good you’ll try to eat with your eyes."
                // className="img-cover"
              />

              <time className="publish-date label-2" datetime="2022-09-03">
                05/04/2024
              </time>
            </div>

            <div className="card-content">
              <p className="card-subtitle label-2 text-center">
                {t("event.ev-h3")}
              </p>

              <h3 className="card-title title-2 text-center">
                {t("event.ev-h2")}
              </h3>
            </div>
          </div>
        </li>

        {/* <li>
          <div className="event-card has-before hover:shine">
            <div
              className="card-banner img-holder"
              style={{ "--width": 350, "--height": 450 }}
            >
              <img
                src={}
                width="350"
                height="450"
                loading="lazy"
                alt="Flavour so good you’ll try to eat with your eyes."
                className="img-cover"
              />

              <time className="publish-date label-2" datetime="2022-09-08">
                25/12/2024
              </time>
            </div>

            <div className="card-content">
              <p className="card-subtitle label-2 text-center">
                {t("event.ev-h2")}
              </p>

              <h3 className="card-title title-2 text-center">
                {t("event.ev-p")}
              </h3>
            </div>
          </div>
        </li> */}
      </ul>

      <a href="/contact" className="btn btn-primary">
        <span className="text text-1">{t("hero.contactus")}</span>

        <span className="text text-2" aria-hidden="true">
          {t("hero.contactus")}
        </span>
      </a>
    </div>
  );
};

export default UpcomingEvents;
