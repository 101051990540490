import React, { useEffect, useState } from "react";
import home1 from "../../assets/images/spagheti1.png";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import UpcomingEvents from "../../components/Sections/UpcomingEvents/UpcomingEvents";
import Img from '../../assets/images/Hotel/exterieur11.webp'
import Imgone from '../../assets/images/Hotel/exterieur12.webp'
import Imgtwo from '../../assets/images/Hotel/exterieur6.webp'
import Imgthree from '../../assets/images/Hotel/exterieur9.webp'
import Imgfour from '../../assets/images/Hotel/swim10.jpg'
import Imgfive from '../../assets/images/Hotel/swim8.jpg'
import Mar from '../../assets/images/Restaurant La Paille/IMG_0727.jpg'
import Marone from '../../assets/images/Restaurant La Paille/IMG_0738.jpg'
import Martwo from '../../assets/images/Restaurant La Paille/restaurant-new-13-930x617.webp'
import Marthree from '../../assets/images/Restaurant La Paille/restaurant-new-14-930x617.webp'
import Marfour from '../../assets/images/Restaurant La Paille/restaurant-new-18-930x617.webp'
import Marfive from '../../assets/images/Restaurant La Paille/restaurant-new-8-930x617.webp'
import Marsix from '../../assets/images/Restaurant La Paille/restaurant1.webp'
import Marseven from '../../assets/images/Restaurant La Paille/restaurant3.webp'
import Mareight from '../../assets/images/Restaurant La Paille/restaurant4.webp'
import Marnine from '../../assets/images/Restaurant La Paille/restaurant6.webp'
import Marten from '../../assets/images/Restaurant La Paille/restaurant7.webp'
import Mareleven from '../../assets/images/Restaurant La Paille/resto.webp'
import Eve from '../../assets/images/Happy Hours/CIMG0015.webp'
import Eveone from '../../assets/images/Happy Hours/CIMG0017.webp'
import Evetwo from '../../assets/images/Happy Hours/IMG_20160415_184534-930x697.webp'
import Evethree from '../../assets/images/Happy Hours/IMG_20160415_184944-930x697.webp'
import Evefour from '../../assets/images/Happy Hours/IMG_20160415_191946-930x697.webp'
import Evefive from '../../assets/images/Happy Hours/IMG_20160415_192016-930x697.webp'
import Evesix from '../../assets/images/Happy Hours/IMG_20160415_192037-930x697.webp'
import Eveseven from '../../assets/images/Happy Hours/IMG_20160415_192200-930x697.webp'
import Eveeight from '../../assets/images/Happy Hours/IMG_20160415_211331-930x697.webp'
import Evenine from '../../assets/images/Happy Hours/IMG_2648-930x619.webp'
import Eveten from '../../assets/images/Happy Hours/IMG_2651-930x619.webp'
import Eveeleven from '../../assets/images/Happy Hours/IMG_2654-930x619.webp'
import Evetwelve from '../../assets/images/Happy Hours/IMG_2686-930x619.webp'
import Evethirteen from '../../assets/images/Happy Hours/IMG_2687-930x619.webp'
import Evefourteen from '../../assets/images/Happy Hours/IMG_2694-930x619.webp'
import Evefifteen from '../../assets/images/Happy Hours/IMG_2708-930x619.webp'
import Evesixteen from '../../assets/images/Happy Hours/IMG_2709-930x619.webp'
import Eveseventeen from '../../assets/images/Happy Hours/IMG_2709.webp'
import Pal from '../../assets/images/Restaurant Le Grill/IMG_0777.jpg'
import Palone from '../../assets/images/Restaurant Le Grill/IMG_0853.jpg'
import Paltwo from '../../assets/images/Restaurant Le Grill/_0762.jpg'
import Palthree from '../../assets/images/Restaurant Le Grill/grill-11-930x617.webp'
import Palfour from '../../assets/images/Restaurant Le Grill/grill-13-930x617.webp'
import Palfive from '../../assets/images/Restaurant Le Grill/grill-19-930x617.webp'
import Palsix from '../../assets/images/Restaurant Le Grill/grill-21-930x617.webp'
import Palseven from '../../assets/images/Restaurant Le Grill/grill-23-930x617.webp'
import Paleight from '../../assets/images/Restaurant Le Grill/grill-27-930x617.webp'
import Palnine from '../../assets/images/Restaurant Le Grill/grill-5-930x617.webp'
import Palten from '../../assets/images/Restaurant Le Grill/grill2.webp'
import Paleleven from '../../assets/images/Restaurant Le Grill/grill3.webp'
import Paltwelve from '../../assets/images/Restaurant Le Grill/grill4.webp'
import Palthirteen from '../../assets/images/Restaurant Le Grill/grill5.webp'
import Palfourteen from '../../assets/images/Restaurant Le Grill/grill6.webp'
import Ane from '../../assets/images/Anniversary/Anniversaire-11-930x522.webp'
import Aneone from '../../assets/images/Anniversary/Anniversaire-20-930x619.webp'
import Anetwo from '../../assets/images/Anniversary/Anniversaire-22-930x619.webp'
import Anethree from '../../assets/images/Anniversary/Anniversaire-22.webp'
import Anefour from '../../assets/images/Anniversary/Anniversaire-31-930x574.webp'
import Anefive from '../../assets/images/Anniversary/Anniversaire-45-930x757.webp'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import {env} from "../../env";


const Gallery = () => {
  const [t] = useTranslation("global");

  const images = [Img, Imgone, Imgtwo, Imgthree, Imgfour, Imgfive];
  const imagesone = [Mar, Marone, Martwo, Marthree, Marfour, Marfive, Marsix, Marseven, Mareight, Marnine, Marten, Mareleven];
  const imagestwo = [Eve, Eveone, Evetwo, Evethree, Evefour, Evefive, Evesix, Eveseven, Eveeight, Evenine, Eveten, Eveeleven, Evetwelve, Evethirteen, Evefourteen, Evefifteen, Evesixteen, Eveseventeen];
  const imagesthree = [Pal, Palone, Paltwo, Palthree, Palfour, Palfive, Palsix, Palseven, Paleight, Palnine, Palten, Paleleven, Paltwelve, Palthirteen, Palfourteen];
  const imagesfour = [Ane, Aneone, Anetwo, Anethree, Anefour, Anefive];
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState(images); // State to keep track of which set of images is currently displayed

  const handleShowModal = (index, set) => {
    setCurrentImageIndex(index);
    setCurrentImages(set);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
  };


  const [showBackToTop, setShowBackToTop] = useState(false);

  // Add an event listener to handle scroll events
  const handleScroll = () => {
    // Show the "Back to Top" button when the user scrolls beyond a certain point
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  };

  const [gallery, setGallery] = useState([]);
  const [error, setError] = useState(null);

  const galleryData = async () => {
    try {
      let response = await fetch(`${env.REACT_APP_API_URL}/gallery`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      response = await response.json();
      setGallery(response?.images);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    galleryData();
  }, []);

  const CustomModal = ({ isOpen, onClose, onNext, onPrev, currentImage }) => {
    useEffect(() => {
      const bodyOverflow = isOpen ? 'hidden' : ''; // Set overflow to hidden when modal is open
      document.body.style.overflow = bodyOverflow;

      return () => {
        document.body.style.overflow = ''; // Reset overflow when modal is closed
      };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
      <div className="modal-overlay-gallery">
        <div className="modal-gallery" onClick={(e) => e.stopPropagation()}>
          <button className="close-button-gallery" onClick={onClose}>
            <IoIosCloseCircle />
          </button>
          <div className="modal-content-gallery">
            <img src={currentImage} alt="" />
            <button className="prev-button" onClick={onPrev}><FaChevronLeft /></button>
            <button className="next-button" onClick={onNext}><FaChevronRight /></button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* [PRELOADER] */}

      <div className="preload" data-preaload>
        <div className="circle"></div>
        <p className="text">LE MARLY</p>
      </div>

      <main>
        <article>
          {/* HERO */}

          <section
            className="hero-about text-center"
            aria-label="home"
            id="home"
          >
            <ul className="hero-about-slider" data-hero-slider>
              {/* LIST ONE */}
              <li className="slider-item active" data-hero-slider-item>
                <div className="slider-bg">
                  <img
                    src={home1}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>

                <p className="label-2 section-subtitle slider-reveal">
                  {t("gallery.g-h")}
                </p>

                <p className="body-2 hero-about-text slider-reveal">
                  {t("gallery.g-h1")}
                </p>
              </li>
              {/* LIST TWO */}
              <li className="slider-item" data-hero-slider-item>
                <div className="slider-bg">
                  <img
                    src={home1}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>

                <p className="label-2 section-subtitle slider-reveal">
                  {t("gallery.g-h")}
                </p>

                <p className="body-2 hero-about-text slider-reveal">
                  {t("gallery.g-h1")}
                </p>
              </li>
              {/* LIST THREE */}

              <li className="slider-item" data-hero-slider-item>
                <div className="slider-bg">
                  <img
                    src={home1}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>

                <p className="label-2 section-subtitle slider-reveal">
                  {t("gallery.g-h")}
                </p>

                <p className="body-2 hero-about-text slider-reveal">
                  {t("gallery.g-h1")}
                </p>
              </li>
            </ul>

            <button
              className="slider-btn prev"
              aria-label="slide to previous"
              data-prev-btn
            >
              <ion-icon name="chevron-back"></ion-icon>
            </button>

            <button
              className="slider-btn next"
              aria-label="slide to next"
              data-next-btn
            >
              <ion-icon name="chevron-forward"></ion-icon>
            </button>
          </section>

          {/* GALLERY */}
          <section className="room top" id="room">
            <div className="container">
              <div className="head-cont top1">
                <h3>{t("gallery.g-h2")}</h3>
                <h1 style={{ fontSize: "25px" }}>{t("gallery.g-h3")}</h1>
                <p>{t("gallery.g-p")}</p>
              </div>

              <div className="heading_top flex1">
                <div className="heading">
                  <h4>{t("gallery.g-h4")}</h4>
                  <div className="content grid" style={{ marginTop: "50px" }}>
                    {images.map((img, index) => (
                      <div className="box" key={index}>
                        <div className="img" onClick={() => handleShowModal(index, images)}>
                          <img src={img} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <CustomModal isOpen={showModal} onClose={handleCloseModal}
                    onPrev={handlePrevImage}
                    onNext={handleNextImage} currentImage={currentImages[currentImageIndex]} />
                </div>
              </div>

              {/* <div className="content grid">
                {gallery[0]?.images?.map((data, index) => (
                  <div className="box">
                    <div className="img">
                      <img src={data?.image} alt="" />
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="heading_top flex1 sec">
                <div className="heading">
                  <h4>{t("gallery.g-h5")}</h4>
                  <div className="content grid" style={{ marginTop: "50px" }}>
                    {imagesone.map((img, index) => (
                      <div className="box" key={index}>
                        <div className="img" onClick={() => handleShowModal(index, imagesone)}>
                          <img src={img} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <CustomModal isOpen={showModal} onClose={handleCloseModal}
                    onPrev={handlePrevImage}
                    onNext={handleNextImage} currentImage={currentImages[currentImageIndex]} />
                </div>
              </div>

              {/* <div className="content grid">
                {gallery[1]?.images?.map((data, index) => (
                  <div className="box">
                    <div className="img">
                      <img src={data?.image} alt="" />
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="heading_top flex1 sec">
                <div className="heading">
                  <h4>{t("gallery.g-h6")}</h4>
                  {/* <h3>{gallery[2]?.section}</h3> */}
                  <div className="content grid" style={{ marginTop: "50px" }}>
                    {imagestwo.map((img, index) => (
                      <div className="box" key={index}>
                        <div className="img" onClick={() => handleShowModal(index, imagestwo)}>
                          <img src={img} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <CustomModal isOpen={showModal} onClose={handleCloseModal}
                    onPrev={handlePrevImage}
                    onNext={handleNextImage} currentImage={currentImages[currentImageIndex]} />
                </div>
              </div>

              {/* <div className="content grid">
                {gallery[2]?.images?.map((data, index) => (
                  <div className="box">
                    <div className="img">
                      <img src={data?.image} alt="" />
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="heading_top flex1 sec">
                <div className="heading">
                  <h4>{t("gallery.g-h7")}</h4>
                  {/* <h3>{gallery[3]?.section}</h3> */}
                  <div className="content grid" style={{ marginTop: "50px" }}>
                    {imagesthree.map((img, index) => (
                      <div className="box" key={index}>
                        <div className="img" onClick={() => handleShowModal(index, imagesthree)}>
                          <img src={img} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <CustomModal isOpen={showModal} onClose={handleCloseModal}
                    onPrev={handlePrevImage}
                    onNext={handleNextImage} currentImage={currentImages[currentImageIndex]} />
                </div>
              </div>

              {/* <div className="content grid">
                {gallery[3]?.images?.map((data, index) => (
                  <div className="box">
                    <div className="img">
                      <img src={data?.image} alt="" />
                    </div>
                  </div>
                ))}
              </div> */}

              <div className="heading_top flex1 sec">
                <div className="heading">
                  <h4>{t("gallery.g-h8")}</h4>
                  {/* <h3>{gallery[4]?.section}</h3> */}
                  <div className="content grid" style={{ marginTop: "50px" }}>
                    {imagesfour.map((img, index) => (
                      <div className="box" key={index}>
                        <div className="img" onClick={() => handleShowModal(index, imagesfour)}>
                          <img src={img} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <CustomModal isOpen={showModal} onClose={handleCloseModal}
                    onPrev={handlePrevImage}
                    onNext={handleNextImage} currentImage={currentImages[currentImageIndex]} />
                </div>
              </div>
              {/* <div className="content grid">
                {gallery[4]?.images?.map((data, index) => (
                  <div className="box">
                    <div className="img">
                      <img src={data?.image} alt="" />
                    </div>
                  </div>
                ))}
              </div> */}

              {/* <div className="heading_top flex1 sec">
                <div className="heading">
                  <h4>{t("gallery.g-h6")}</h4>
                  <h3>{gallery[5]?.section}</h3>
                </div>
              </div> */}

              {/* <div className="content grid">
                {gallery[5]?.images?.map((data, index) => (
                  <div className="box">
                    <div className="img">
                      <img src={data?.image} alt="" />
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </section>

          {/* GALLERY END */}

          {/* EVENT */}

          <section className="section event bg-black-10" aria-label="event">

          </section>
        </article>
      </main>

      {/* BACK TO TOP */}

      <button
        href=""
        onClick={scrollToTop}
        className="back-top-btn active"
        aria-label="back to top"
        data-back-top-btn
      >
        <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </button>
    </div>

  );
};



export default Gallery;
