import React from "react";
import "../Rooms/Rooms.css";
import { useTranslation } from "react-i18next";
import restaurant01 from "../../../assets/images/restaurant01.jpg";
import restaurant02 from "../../../assets/images/restaurant02.jpg";
import restaurant03 from "../../../assets/images/restaurant03.webp";
import restaurant04 from "../../../assets/images/restaurant04.webp";
import restaurant05 from "../../../assets/images/restaurant05.jpg";
import restaurant06 from "../../../assets/images/restaurant06.webp";
import bar01 from "../../../assets/images/restaurant-bar-01.webp";
import bar02 from "../../../assets/images/restaurant-bar-02.webp";
import bar03 from "../../../assets/images/restaurant-bar-03.webp";
import bar04 from "../../../assets/images/restaurant-bar-04.webp";
import bar05 from "../../../assets/images/restaurant-bar-05.jpg";
import bar06 from "../../../assets/images/restaurant-bar-06.jpg";
import conf1 from "../../../assets/images/conf1.webp";
import conf2 from "../../../assets/images/conf2.webp";
import conf3 from "../../../assets/images/conf3.webp";
import anniversaire01 from "../../../assets/images/anniversaire01.webp";
import birthday01 from "../../../assets/images/birthday01.webp";
import afterwork01 from "../../../assets/images/after_work_01.webp";
import swimming01 from "../../../assets/images/swimming01.jpg";
import swimming02 from "../../../assets/images/swimming02.jpg";
import swimming03 from "../../../assets/images/swimming03.jpg";
import Slider from "react-slick";
import MenuPdf from "../../../assets/MENU_MARLY.pdf";

const Restaurant = () => {
  const [t, i18n] = useTranslation("global");
  const textClass = i18n.language === 'en' ? 'text_first_eng' : 'text_first_frn';
  const textsClass = i18n.language === 'en' ? 'text_second_eng' : 'text_second_frn';
  const textesClass = i18n.language === 'en' ? 'text' : 'text_third_frn';

  return (
    <div>
      <section className="room top one" id="room">
        <div className="container">
          <div className="head-cont top1">
            <h3>{t("rooms.heading-4")}</h3>
            <br />
            <h1 style={{ fontSize: "25px" }}>{t("rooms.para-one")}</h1>
            <br />
            <h1 style={{ fontSize: "25px" }}>{t("rooms.par-two")}</h1>
            <p>{t("rooms.para-two")}</p>
          </div>

          <div className="heading_top flex1">
            <div className="heading">
              <h4>{t("rooms.comf")}</h4>
              <h3>{t("rooms.grill")}</h3>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="btn1"
                onClick={() => {
                  window.open(MenuPdf, "_blank");
                }}
              >
                {" "}
                <a href="#">{t("rooms.v-all")}</a>
              </button>
            </div>
          </div>

          <div className="content grid" style={{ marginTop: "50px" }}>
            {/* HOTEL ROOMS CARDS 1 */}
            <div className="box">
              <div className="img">
                <img src={restaurant01} alt="" />
              </div>
              <div className={textClass}>
                <h3>{t("rooms.card25-h3")}</h3>
                <p>{t("rooms.card225-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARDS ONE END  */}
            {/* CARD 2 START */}
            <div className="box">
              <div className="img">
                <img src={restaurant02} alt="" />
              </div>
              <div className={textClass}>
                <h3>{t("rooms.card5-h3")}</h3>
                <p>{t("rooms.card5-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>

            {/* CARD 2 END */}

            {/* CARD 3 START */}
            <div className="box">
              <div className="img">
                <img src={restaurant03} alt="" />
              </div>
              <div className={textClass}>
                <h3>{t("rooms.card6-h3")}</h3>
                <p>{t("rooms.card6-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD THREE END */}
            {/* CARD 4 START */}
            <div className="box">
              <div className="img">
                <img src={restaurant04} alt="" />
              </div>
              <div className={textClass}>
                <h3>{t("rooms.card7-h3")}</h3>
                <p>{t("rooms.card7-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD 4 END */}
            {/* CARD 5 START */}
            <div className="box">
              <div className="img">
                <img src={restaurant05} alt="" />
              </div>
              <div className={textClass}>
                <h3>{t("rooms.card8-h3")}</h3>
                <p>{t("rooms.card8-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD 5 END */}
            {/* CARD 6 START */}
            <div className="box">
              <div className="img">
                <img src={restaurant06} alt="" />
              </div>
              <div className={textClass}>
                <h3>{t("rooms.card9-h3")}</h3>
                <p>{t("rooms.card9-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD 6 END */}
          </div>

          {/* La Paille Restaurant */}

          <div className="head-cont top1">
            <h3>{t("rooms.heading-one")}</h3>
            <br />
            <h1 style={{ fontSize: "25px" }}>{t("rooms.paragraph-one")}</h1>
            <p>{t("rooms.paragraph-two")}</p>
          </div>
          <div className="heading_top flex1 sec">
            <div className="heading">
              <h4>{t("rooms.comf")}</h4>
              <h3>{t("rooms.par-bar")}</h3>
            </div>
          </div>

          <div className="content grid" style={{ marginTop: "50px" }}>
            {/* HOTEL ROOMS CARDS 1 */}
            <div className="box">
              <div className="img">
                <img src={bar01} alt="" />
              </div>
              <div className={textsClass}>
                <h3>{t("rooms.card10-h3")}</h3>
                <p>{t("rooms.card10-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARDS ONE END  */}
            {/* CARD 2 START */}
            <div className="box">
              <div className="img">
                <img src={bar02} alt="" />
              </div>
              <div className={textsClass}>
                <h3>{t("rooms.card11-h3")}</h3>
                <p>{t("rooms.card11-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>

            {/* CARD 2 END */}

            {/* CARD 3 START */}
            <div className="box">
              <div className="img">
                <img src={bar03} alt="" />
              </div>
              <div className={textsClass}>
                <h3>{t("rooms.card12-h3")}</h3>
                <p>{t("rooms.card12-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD THREE END */}
            {/* CARD 4 START */}
            <div className="box">
              <div className="img">
                <img src={bar04} alt="" />
              </div>
              <div className={textsClass} style={{marginTop: "20px"}}>
                <h3>{t("rooms.card13-h3")}</h3>
                <p>{t("rooms.card13-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD 4 END */}
            {/* CARD 5 START */}
            <div className="box">
              <div className="img">
                <img src={bar05} alt="" />
              </div>
              <div className={textsClass}>
                <h3>{t("rooms.card14-h3")}</h3>
                <p>{t("rooms.card14-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD 5 END */}
            {/* CARD 6 START */}
            <div className="box">
              <div className="img">
                <img src={bar06} alt="" />
              </div>
              <div className={textsClass}>
                <h3>{t("rooms.card15-h3")}</h3>
                <p>{t("rooms.card15-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
            </div>
            {/* CARD 6 END */}
          </div>

          {/* Meetings & Events */}

          <div className="head-cont top1">
            <h3>{t("rooms.rooms-h")}</h3>
            <h1 style={{ fontSize: "25px" }}>{t("rooms.rooms-p")}</h1>
            <p>{t("rooms.rooms-para")}</p>
          </div>

          <div className="heading_top flex1">
            <div className="heading">
              <h4>{t("rooms.comf")}</h4>
              <h3>{t("rooms.roomsh1")}</h3>
            </div>
          </div>

          <div className="content grid" style={{ marginTop: "50px" }}>
            {/* CARD 1 START */}
            <div className="box">
              <div className="img">
                <img src={conf1} alt="" />
              </div>
              <div className="text">
                <h3>{t("rooms.card16-h3")}</h3>
                <p>{t("rooms.card16-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 1 END */}
            {/* CARD 2 START */}
            <div className="box">
              <div className="img">
                <img src={conf2} alt="" />
              </div>
              <div className="text">
                <h3>{t("rooms.card17-h3")}</h3>
                <p>{t("rooms.card17-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 2 END */}
            {/* CARD 3 START */}
            <div className="box">
              <div className="img">
                <img src={conf3} alt="" />
              </div>
              <div className="text">
                <h3>{t("rooms.card18-h3")}</h3>
                <p>{t("rooms.card18-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 3 END */}
          </div>

          {/* Events */}

          <div className="heading_top flex1 sec">
            <div className="heading">
              <h4>{t("rooms.comf")}</h4>
              <h3>{t("rooms.eve")}</h3>
            </div>
          </div>

          <div className="content grid"style={{marginTop: "50px"}}>
            {/* CARD 1 START */}
            <div className="box">
              <div className="img">
                <img src={anniversaire01} alt="" />
              </div>
              <div className={textesClass}>
                <h3>{t("rooms.card19-h3")}</h3>
                <p>{t("rooms.card19-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 1 END */}
            {/* CARD 2 START */}
            <div className="box">
              <div className="img">
                <img src={birthday01} alt="" />
              </div>
              <div className={textesClass}>
                <h3>{t("rooms.card20-h3")}</h3>
                <p>{t("rooms.card20-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 2 END */}
            {/* CARD 3 START */}
            <div className="box">
              <div className="img">
                <img src={afterwork01} alt="" />
              </div>
              <div className={textesClass}>
                <h3>{t("rooms.card21-h3")}</h3>
                <p>{t("rooms.card21-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 3 END */}
          </div>

          {/* Swimming Pool */}

          <div className="heading_top flex1 sec">
            <div className="heading">
              <h4>{t("rooms.comf")}</h4>
              <h3>{t("rooms.pool")}</h3>
            </div>
          </div>

          <div className="content grid" style={{marginTop: "50px"}}>
            {/* CARD 1 START */}
            <div className="box">
              <div className="img">
                <img src={swimming01} alt="" />
              </div>
              <div className={textesClass}>
                <h3>{t("rooms.card22-h3")}</h3>
                <p>{t("rooms.card22-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 1 END */}
            {/* CARD 2 START */}
            <div className="box">
              <div className="img">
                <img src={swimming02} alt="" />
              </div>
              <div className={textesClass}>
                <h3>{t("rooms.card23-h3")}</h3>
                <p>{t("rooms.card23-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 2 END */}
            {/* CARD 3 START */}
            <div className="box">
              <div className="img">
                <img src={swimming03} alt="" />
              </div>
              <div className={textesClass}>
                <h3>{t("rooms.card24-h3")}</h3>
                <p>{t("rooms.card24-p")}</p>
              </div>
              <div className="rooms-fa-icons">
                <i className="fas fa-bed"></i>
                <i className="fas fa-wifi"></i>
                <i className="fas fa-car"></i>
                <i className="fas fa-coffee"></i>
                <i className="fas fa-swimming-pool"></i>
              </div>
              <div class="book-now-container">
                <div class="animation-container">
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                  <a href="/contact" class="book-now-link">
                    {t("hero.contactus")}{" "}
                    <span class="icon">
                      {" "}
                      <i class="fas fa-phone"></i>
                    </span>
                  </a>
                  <div class="round-objects">
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                    <div class="round-object"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* CARD 3 END */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Restaurant;
