import React, { useEffect, useState, useRef } from "react";
import Rooms from "../Sections/Rooms/Room";
import Book from "../Sections/Hero/Hero";
import Restaurant from "../Sections/Restaurant/Restaurant";
import testimonialImage from "../../assets/images/testimonial-bg.jpg";
import home1 from "../../assets/images/HomeImage.svg";
import home4 from "../../assets/images/hero-icon.png";
import Beach from '../../assets/images/beach.jpg'
import specialDish from "../../assets/images/special-dish-banner.jpg";
import Badge1 from "../../assets/images/badge-1.png";
import Shape4 from "../../assets/images/shape-4.png";
import Shape9 from "../../assets/images/shape-9.png";
import Menu1 from "../../assets/images/gastronomy1.png";
import Menu2 from "../../assets/images/gastronomy2.png";
import Menu3 from "../../assets/images/gastronomy3.png";
import Menu4 from "../../assets/images/gastronomy4.png";
import Menu5 from "../../assets/images/gastronomy5.png";
import Menu6 from "../../assets/images/gastronomy6.png";
import Shape5 from "../../assets/images/shape-5.png";
import Shape6 from "../../assets/images/shape-6.png";
import { useTranslation } from "react-i18next";
import ContactUs from "../Sections/ContactUs";
import HeroModal from "../Sections/HeroModal";
import axios from "axios";
import { toast } from "react-toastify";
import OurStory from "../Sections/OurStory/OurStory";
import UpcomingEvents from "../Sections/UpcomingEvents/UpcomingEvents";
import WhyChooseUs from "../Sections/WhyChooseUs/WhyChooseUs";
import OfferedServices from "../Sections/OfferedServices/OfferedServices";
import { CircularProgress, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import slider1 from '../../assets/images/slider1.png'
import homeslider from "../../assets/images/home-slider.jpg";
import homeSlider03 from "../../assets/images/spagheti1.png";
import VideoComponent from "../Video/videoSection";
import { useNavigate } from "react-router-dom";
import { env } from "../../env";
const Home = () => {
  const navigate = useNavigate()
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const [t] = useTranslation("global");
  const [modalopen, setModalopen] = useState(false);
  const handleModal = () => {
    setModalopen(!modalopen);
  };

  const [showBackToTop, setShowBackToTop] = useState(false);

  // Add an event listener to handle scroll events
  const handleScroll = () => {
    // Show the "Back to Top" button when the user scrolls beyond a certain point
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };
  const handleChange = (e) => {
    const { value, name } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      window.location.href = 'https://live.ipms247.com/booking/book-rooms-hotellemarly';
      const response = await axios.post(
        `${env.REACT_APP_API_URL}/api/reservation`,
        state
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setState({
          checkInDate: "",
          checkOutDate: "",
          adults: "",
          children: "",
          numberOfRooms: "",
          email: "",
        });
      }
    } catch (error) {
      toast.error(error?.message ?? "Something Went Wrong");
    }
    setLoading(false);
  };
  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  };

  const sliderRef = useRef(null);

  const nextSlide = () => {
    const slider = sliderRef.current;
    const currentSlide = slider.querySelector('.slider-item.active');
    const nextSlide = currentSlide.nextElementSibling;

    if (nextSlide) {
      currentSlide.classList.remove('active');
      nextSlide.classList.add('active');
    } else {
      // If next slide doesn't exist, wrap around to the first slide
      const firstSlide = slider.querySelector('.slider-item');
      currentSlide.classList.remove('active');
      firstSlide.classList.add('active');
    }
  };

  return (
    <div>
      {/* [PRELOADER] */}

      <div className="preload" data-preaload>
        <div className="circle"></div>
        <p className="text">{t("hero.loading")}</p>
      </div>

      <main className={`${modalopen ? "backdrop" : ""}`}>
        <article>
          {/* HERO */}

          <section className="hero text-center" aria-label="home" id="home" ref={sliderRef}>
            <ul className="hero-slider" data-hero-slider>

            <li className="slider-item active" data-hero-slider-item>
                <div className="slider-bg slide-overly">
                  <img
                    src={homeslider}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="label-2 section-subtitle slider-reveal">
                  {t("hero.d-two")}
                </p>

                <h1 className="display-1 hero-title slider-reveal">
                  {t("hero.h-three")} <br />
                  {t("hero.h-four")}
                </h1>

                <p className="body-2 hero-text slider-reveal">
                  {t("hero.p-two")}
                </p>

                
                <a href="/contact" className="btn btn-primary slider-reveal">
                  <span className="text text-1">{t("hero.contactus")}</span>

                  <span className="text text-2" aria-hidden="true">
                    {t("hero.contactus")}
                  </span>
                </a>
              </li>
              <li className="slider-item" data-hero-slider-item>
                <div className="slider-bg slide-overly">
                  <img
                    src={Beach}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="label-2 section-subtitle slider-reveal">
                  {t("hero.d-three")}
                </p>
                <h1
                  className="display-1 hero-title slider-reveal"
                  style={{ fontWeight: "bold" }}
                >
                  {t("hero.h-five")}
                  <br />
                  {t("hero.h-six")}
                </h1>
                <p className="body-2 hero-text slider-reveal">
                  {t("hero.p-three")}
                </p>
               

                <a href="/contact" className="btn btn-primary slider-reveal">
                  <span className="text text-1">{t("hero.contactus")}</span>

                  <span className="text text-2" aria-hidden="true">
                    {t("hero.contactus")}
                  </span>
                </a>
              </li>
              
              <li className="slider-item" data-hero-slider-item>
                <div className="slider-bg slide-overly">
                  <img
                    src={homeSlider03}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="label-2 section-subtitle slider-reveal">
                  {t("hero.d-one")}
                </p>

                <h1 className="display-1 hero-title slider-reveal">
                  {t("hero.h-one")} <br />
                  {t("hero.h-two")}
                </h1>

                <p className="body-2 hero-text slider-reveal">
                  {t("hero.p-one")}
                </p>
                
                <a href="/contact" className="btn btn-primary slider-reveal">
                  <span className="text text-1">{t("hero.contactus")}</span>
                  <span className="text text-2" aria-hidden="true">
                    {t("hero.contactus")}
                  </span>
                </a>
              </li>
              
            </ul>

            <button
              className="slider-btn prev"
              aria-label="slide to previous"
              data-prev-btn
            >
              <ion-icon name="chevron-back"></ion-icon>
            </button>

            <button
              className="slider-btn next"
              aria-label="slide to next"
              data-next-btn
              onClick={nextSlide}
            >
              <ion-icon name="chevron-forward"></ion-icon>
            </button>

            <a
              className="hero-btn has-after"
              href="https://live.ipms247.com/booking/book-rooms-hotellemarly"
              style={{ cursor: "pointer" }}
            >
              <img src={home4} width="48" height="48" alt="booking icon" />

              <span className="label-2 text-center span">
                {t("hero.booknow")}
              </span>
            </a>
          </section>

          {/* BOOK */}
          <Book />
          <VideoComponent />

          {/* BOOK END */}

          {/* SERVICES */}

          <section
            className="section service bg-black-10 text-center"
            aria-label="service"
          >
            <OfferedServices />
          </section>

          {/* ABOUT */}

          <section
            className="section about text-center"
            aria-labelledby="about-label"
            id="about"
          >
            <OurStory />
          </section>

          <Rooms />
          <Restaurant />

          {/* SPECIAL DISH */}

          <section
            className="special-dish text-center"
            aria-labelledby="dish-label"
          >
            <div className="special-dish-banner">
              <img
                src={specialDish}
                width="940"
                height="900"
                loading="lazy"
                alt="special dish"
                className="img-cover"
              />
            </div>

            <div className="special-dish-content bg-black-10">
              <div className="container">
                <img
                  src={Badge1}
                  width="28"
                  height="41"
                  loading="lazy"
                  alt="badge"
                  className="abs-img"
                />

                <p className="section-subtitle label-2">{t("dish.d-t")}</p>

                <h2 className="headline-1 section-title">{t("dish.sig")}</h2>

                <p className="section-text">{t("dish.d-txt")}</p>

                <div className="wrapper">
                  <del className="del body-3">$$ - </del>

                  <span className="span body-1">$$ - {t("dish.d-dlr")}</span>
                </div>

                <a href="/contact" className="btn btn-primary">
                  <span className="text text-1">{t("dish.b-tble")}</span>

                  <span className="text text-2" aria-hidden="true">
                    {t("dish.b-tble")}
                  </span>
                </a>
              </div>
            </div>

            <img
              src={Shape4}
              width="179"
              height="359"
              loading="lazy"
              alt=""
              className="shape shape-1"
            />

            <img
              src={Shape9}
              width="351"
              height="462"
              loading="lazy"
              alt=""
              className="shape shape-2"
            />
          </section>

          {/* MENU */}
          <section className="section menu" aria-label="menu-label" id="menu">
            <div className="container">
              <p className="section-subtitle text-center label-2">
                {t("dish.mn-t")}
              </p>

              <h2 className="headline-1 section-title text-center">
                {t("dish.d-mn-t")}
              </h2>
              <div className="head-cont">
                <p>{t("dish.gastro-sub")}</p>
              </div>

              <ul className="grid-list">
                <li>
                  <div className="menu-card hover:card">
                    <figure
                      className="card-banner img-holder"
                      style={{ "--width": 100, "--height": 100 }}
                    >
                      <img
                        src={Menu1}
                        width="100"
                        height="100"
                        loading="lazy"
                        alt="Greek Salad"
                        className="img-cover"
                      />
                    </figure>

                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="/" className="card-title">
                            {t("dish.greek")}
                          </a>
                        </h3>
                      </div>
                      <p className="card-text label-1">{t("dish.m-p1")}</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="menu-card hover:card">
                    <figure
                      className="card-banner img-holder"
                      style={{ "--width": 100, "--height": 100 }}
                    >
                      <img
                        src={Menu4}
                        width="100"
                        height="100"
                        loading="lazy"
                        alt="Butternut Pumpkin"
                        className="img-cover"
                      />
                    </figure>

                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="/" className="card-title">
                            {t("dish.tit3")}
                          </a>
                        </h3>
                      </div>

                      <p className="card-text label-1">{t("dish.m-p7")}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="menu-card hover:card">
                    <figure
                      className="card-banner img-holder"
                      style={{ "--width": 100, "--height": 100 }}
                    >
                      <img
                        src={Menu2}
                        width="100"
                        height="100"
                        loading="lazy"
                        alt="Lasagne"
                        className="img-cover"
                      />
                    </figure>

                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="/" className="card-title">
                            {t("dish.tit")}
                          </a>
                        </h3>
                      </div>

                      <p className="card-text label-1">{t("dish.m-p2")}</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="menu-card hover:card">
                    <figure
                      className="card-banner img-holder"
                      style={{ "--width": 100, "--height": 100 }}
                    >
                      <img
                        src={Menu5}
                        width="100"
                        height="100"
                        loading="lazy"
                        alt="Tokusen Wagyu"
                        className="img-cover"
                      />
                    </figure>

                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="/" className="card-title">
                            {t("dish.tit4")}
                          </a>
                        </h3>

                      </div>

                      <p className="card-text label-1">{t("dish.m-p3")}</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="menu-card hover:card">
                    <figure
                      className="card-banner img-holder"
                      style={{ "--width": 100, "--height": 100 }}
                    >
                      <img
                        src={Menu3}
                        width="100"
                        height="100"
                        loading="lazy"
                        alt="Olivas Rellenas"
                        className="img-cover"
                      />
                    </figure>

                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="/" className="card-title">
                            {t("dish.tit2")}
                          </a>
                        </h3>
                      </div>

                      <p className="card-text label-1">{t("dish.m-p6")}</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="menu-card hover:card">
                    <figure
                      className="card-banner img-holder"
                      style={{ "--width": 100, "--height": 100 }}
                    >
                      <img
                        src={Menu6}
                        width="100"
                        height="100"
                        loading="lazy"
                        alt="Opu Fish"
                        className="img-cover"
                      />
                    </figure>

                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="/" className="card-title">
                            {t("dish.tit5")}
                          </a>
                        </h3>
                      </div>

                      <p className="card-text label-1">{t("dish.m-p")}</p>
                    </div>
                  </div>
                </li>
              </ul>

              <p className="menu-text text-center">
                {t("dish.con")} <span className="span">{t("dish.bk")}</span>{" "}
                {t("dish.a")} <span className="span">{t("dish.tbl")}</span>
              </p>

              <a href="/contact" className="btn btn-primary">
                <span className="text text-1">{t("hero.contactus")}</span>

                <span className="text text-2" aria-hidden="true">
                  {t("hero.contactus")}
                </span>
              </a>

              <img
                src={Shape5}
                width="921"
                height="1036"
                loading="lazy"
                alt="shape"
                className="shape shape-2 move-anim"
              />
              <img
                src={Shape6}
                width="343"
                height="345"
                loading="lazy"
                alt="shape"
                className="shape shape-3 move-anim"
              />
            </div>
          </section>

          {/* TESTIMONIALS */}

          <section
            className="section testi text-center has-bg-image"
            style={{
              backgroundImage: `url(${testimonialImage})`,
              backgroundColor: "black", // Fallback color
            }}
            aria-label="testimonials"
          >
            <div className="container">
              <div className="quote">”</div>

              <p className="headline-2 testi-text">{t("dish.test")}</p>

              <div className="wrapper">
                <div className="separator"></div>
                <div className="separator"></div>
                <div className="separator"></div>
              </div>

              <div className="profile">
                <p className="label-2 profile-name">{t("dish.frm")}</p>
              </div>
            </div>
          </section>

          {/* RESERVATION */}
          <section className="reservation">
            <ContactUs />
          </section>

          {/* FEATURES */}

          <section
            className="section features text-center"
            aria-label="features"
          >
            <WhyChooseUs />
          </section>

          {/* EVENT */}

          <section className="section event bg-black-10" aria-label="event">
            <UpcomingEvents />
          </section>
        </article>
      </main>

      {/* BACK TO TOP */}

      <button
        href=""
        className="back-top-btn active"
        aria-label="back to top"
        data-back-top-btn
        onClick={scrollToTop}
      >
        <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </button>

      <div className={`Hero-Modal${modalopen ? "-Active" : ""}`}>
        <form
          className={`${modalopen ? "form-left" : "form-left-inactive"}`}
          onSubmit={handleSubmit}
          style={{ paddingTop: "20px" }}
        >
          <div className="close-icon">
            <ion-icon name="close-outline" onClick={handleModal}></ion-icon>
          </div>
          <div className="input-wrapper input-wrappers">
            <div className="box image-boxs" style={{ marginTop: "20px" }}>
              <div>
                <i class="fas fa-calendar-check"></i>
                <label>{t("book.checkin")}</label>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                  type="date"
                  name="checkInDate"
                  placeholder="Check-in-Date"
                  autocomplete="off"
                  className="input-field input-fields"
                  onChange={handleChange}
                  value={state?.checkInDate}
                  required
                  sx={{
                    width: "70%",
                    height: "40px",
                    "& input": {
                      color: "white",
                      textTransform: "uppercase",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "20px",
                      height: "38px",
                      padding: "0px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      border: "1px solid var(--white-alpha-10)",
                    },
                    "@media (max-width: 650px)": {
                      height: "45px",
                      "& input": {
                        height: "43px",
                      },
                    },
                    "@media (max-width: 500px)": {
                      width: "50%",
                      height: "45px",
                      "& input": {
                        height: "43px",
                        fontSize: "18px",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="box image-boxs" style={{ marginTop: "20px" }}>
              <div>
                <i class="fas fa-calendar-times"></i>
                <label>{t("book.checkout")}</label>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                  className="input-field input-fields"
                  type="date"
                  placeholder="Check-out-Date"
                  name="checkOutDate"
                  onChange={handleChange}
                  value={state?.checkOutDate}
                  required
                  sx={{
                    width: "70%",
                    height: "40px",
                    "& input": {
                      color: "white",
                      textTransform: "uppercase",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "20px",
                      height: "38px",
                      padding: "0px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      border: "1px solid var(--white-alpha-10)",
                    },
                    "@media (max-width: 650px)": {
                      height: "45px",
                      "& input": {
                        height: "43px",
                      },
                    },
                    "@media (max-width: 500px)": {
                      width: "50%",
                      "& input": {
                        height: "43px",
                        fontSize: "18px",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="box image-boxs">
              <div className="flexTitle">
                <div>
                  <i class="fas fa-user"></i>
                  <label>{t("book.adults")}</label>
                </div>
              </div>
              <input
                className="input-field input-fields"
                type="number"
                placeholder="0"
                style={{ height: "40px", marginBlockEnd: "5px" }}
                name="adults"
                onChange={handleChange}
                value={state?.adults}
                required
              />
            </div>
            <div className="box image-boxs">
              <div className="flexTitle">
                <div>
                  <i class="fas fa-child"></i>
                  <label>{t("book.child")}</label>
                </div>
              </div>
              <input
                className="input-field input-fields"
                type="number"
                placeholder="0"
                style={{ height: "40px", marginBlockEnd: "5px" }}
                name="children"
                onChange={handleChange}
                value={state?.children}
              />
            </div>
            <div className="box image-boxs">
              <div className="flexTitle">
                <div>
                  <i class="fas fa-house"></i>
                  <label>{t("book.rooms")}</label>
                </div>
              </div>
              <input
                className="input-field input-fields"
                type="number"
                placeholder="0"
                style={{ height: "40px", marginBlockEnd: "5px" }}
                name="numberOfRooms"
                onChange={handleChange}
                value={state?.numberOfRooms}
                required
              />
            </div>
            <div className="box image-boxs">
              <div className="flexTitle">
                <div>
                  <i class="fa-solid fa-envelope"></i>
                  <label>E-mail:</label>
                </div>
              </div>
              <input
                className="input-field input-fields"
                type="email"
                style={{ height: "40px", marginBlockEnd: "5px" }}
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={state?.email}
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-secondary"
            style={{ paddingTop: "7px", paddingBottom: "7px" }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <CircularProgress
                  style={{ height: "20px", width: "20px" }}
                  color="inherit"
                />
                Loading...
              </div>
            ) : (
              <>
                <span className="text text-1">{t("hero.booknow")}</span>
                <span className="text text-2" style={{ color: "white" }}>
                  {t("hero.booknow")}
                </span>
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Home;
