import React, { useState } from "react";
import footerBg from "../../assets/images/footer-bg.jpg";
import logo from "../../assets/images/nav-logo.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import {env} from "../../env";
const Footer = () => {
  const [t] = useTranslation("global");
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();

      const response = await axios.post(
        `${env.REACT_APP_API_URL}/api/subscribe`,
        state
      );

      if (response?.data?.success) {
        console.log(response.data);
        toast.success(response?.data?.message);
        setState({
          email: "",
        });
      }
    } catch (error) {
      if (error?.name === "AxiosError") {
        toast.error("Already subcribed");
      }
      else {
        toast.error(error?.message ?? "Something Went Wrong");
      }
    }
    setLoading(false);
  };
  return (
    <div>
      {/* FOOTER */}

      <footer
        className="footer section has-bg-image text-center"
        style={{ backgroundImage: `url(${footerBg})` }}
      >
        <div className="container">
          <div className="footer-top grid-list">
            <div className="footer-brand has-before has-after">
              <a href="#" className="logo">
                <img
                  src={logo}
                  width="160"
                  height="50"
                  loading="lazy"
                  alt="grilli home"
                />
              </a>

              <address className="body-4">{t("navbar.location")}</address>

              <a href="tel:+2252721212151" className="body-4 contact-link">
                {t("navbar.booking")} : +2252721212151
              </a>

              <p className="body-4">{t("navbar.time")}</p>

              <div className="wrapper">
                <div className="separator"></div>
                <div className="separator"></div>
                <div className="separator"></div>
              </div>

              <p className="title-1">{t("navbar.news")}</p>

              <p className="label-1">
                {t("navbar.sub")}{" "}
                <span className="span">{t("navbar.newsletter")}</span>
              </p>

              <form action="" className="input-wrapper" onSubmit={handleSubmit}>
                <div className="icon-wrapper">
                  <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>

                  <input
                    type="email"
                    name="email"
                    placeholder="Your email"
                    autocomplete="off"
                    className="input-field"
                    onChange={handleChange}
                    value={state?.email}
                    required
                  />
                </div>

                <button type="submit" className="btn btn-secondary">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <CircularProgress
                        style={{ height: "20px", width: "20px" }}
                        color="inherit"
                      />
                      Loading...
                    </div>
                  ) : (
                    <>
                      <span className="text text-1">{t("navbar.subbtn")}</span>

                      <span className="text text-2" aria-hidden="true">
                        {t("navbar.subbtn")}
                      </span>
                    </>
                  )}
                </button>
              </form>
            </div>

            <ul className="footer-list">
              <li>
                <a href="/" className="label-2 footer-link hover-underline">
                  {t("navbar.home")}
                </a>
              </li>

              <li>
                <a
                  href="/about"
                  className="label-2 footer-link hover-underline"
                >
                  {t("navbar.aboutus")}
                </a>
              </li>
              <li>
                <a
                  href="/rooms"
                  className="label-2 footer-link hover-underline"
                >
                  {t("navbar.accommodation")}
                </a>
              </li>
              <li>
                <a
                  href="/restaurant"
                  className="label-2 footer-link hover-underline"
                >
                  Restaurant
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="label-2 footer-link hover-underline"
                >
                  {t("navbar.services")}
                </a>
              </li>

              <li>
                <a
                  href="/gallery"
                  className="label-2 footer-link hover-underline"
                >
                  {t("navbar.gallery")}
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="label-2 footer-link hover-underline"
                >
                  {t("navbar.contact")}
                </a>
              </li>
            </ul>

            <ul className="footer-list">
              <li>
                <a
                  href="https://www.facebook.com/hotelmarlyabidjan/"
                  className="label-2 footer-link hover-underline"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/hotelmarly_ci"
                  className="label-2 footer-link hover-underline"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/marly_ci"
                  className="label-2 footer-link hover-underline"
                  target="_blank"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@hotel.le.marly"
                  className="label-2 footer-link hover-underline"

                >
                  Tiktok
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/H%C3%B4tel+Le+Marly/@6.3500581,-2.7742593,5.63z/data=!4m18!1m9!3m8!1s0xfc1eec182a3b403:0xea3cee2fa41e350f!2sLe+Marly!5m2!4m1!1i2!8m2!3d5.2720915!4d-3.9699617!3m7!1s0xfc1eec184a6d3a1:0x6c06005e6fdc4459!5m2!4m1!1i2!8m2!3d5.2721032!4d-3.9696569"
                  className="label-2 footer-link hover-underline"
                  target="_blank"
                >
                  Google Map
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-bottom">
            <p className="copyright">&copy; 2024 {t("navbar.copy")}</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
